import * as React from "react"
import Seo from "../../components/seo"
import PrivateRoute from "../../components/privateRoute"
import ReduxWrapper from "../../redux/reduxWrapper"
import AdminLogin from "../../components/SuperAdmin/adminLogin"
import AdminPrivateRoute from "./adminPrivateRoute"

const LoginPage = props => {
  return (
    <React.Fragment>
      <Seo title="Login" />
      <AdminPrivateRoute component={AdminLogin} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<LoginPage {...props} />} />
export default WrappedPage
