import React, { useState } from "react"
import LoginFooter from "../loginFooter"
import { navigate } from "gatsby"
import AxiosInstance from "../../services/axiosInstance"
import Axios from "axios"

const AdminLogin = () => {
  const [username, setUsername] = useState(""),
    [password, setPassword] = useState(""),
    [errorMessage, setErrorMessage] = useState(""),
    [values, setValues] = useState({
      loading: false,
    })

  const { loading } = values

  const signin = async user => {
    let formData = new FormData()
    formData.append("username", String(user.email))
    formData.append("password", user.password)
    formData.append("grant_type", process.env.GATSBY_USER_GRANT_TYPE_LOGIN)
    formData.append("scope", process.env.GATSBY_ADMIN_SCOPE)
    formData.append("client_id", process.env.GATSBY_ADMIN_CLIENT_ID)
    formData.append("client_secret", process.env.GATSBY_ADMIN_CLIENT_SECRET)

    let responseData = Axios.post(process.env.GATSBY_API_URL + "/token", formData, {
      headers: {
        Accept: "*/*",
      },
    })
      .then(response => {
        console.log(response)
        return response.data
      })
      .catch(error => {
        setErrorMessage(error.response.data.message)
        setValues({
          ...values,
          loading: false,
        })
      })
    console.log(responseData)
    return responseData
  }

  const handleLogin = (event, email, password) => {
    event.preventDefault()
    setValues({ ...values, loading: true })
    signin({ email, password })
      .then(data => {
        if (data?.access_token) {
          authenticate(data, () => {
            setValues({
              ...values,
              loading: false,
            })
          })
        }
      })
      .catch(error => {
        console.log(error)
        console.log("something  went wrong ")
      })
  }

  // const setUserDetails = async token => {
  //   AxiosInstance.get("/settings/general-setting/all-data").then(response => {
  //     setUserData(response.data, () => {
  //       setValues({
  //         ...values,
  //         loading: false,
  //       })
  //     })
  //   })
  // }

  // const setUserData = userData => {
  //   userData.data.map((data, index) => {
  //     localStorage.setItem(data.type, data.value)
  //   })
  //   navigate("/superAdmin0rama")
  // }

  const authenticate = data => {
    if (data.access_token) {
      localStorage.setItem("adminJwt", data.access_token)
      localStorage.setItem("adminRefreshJwt", data.refresh_token)
      navigate("/superAdmin0rama")
      // setUserDetails(data.access_token)
    }
  }

  return (
    <>
      <section className="loginSection">
        <div className="loginContainer">
          <div className="row">
            <div className="col-md-12 col-xs-12 text-center">
              <div className="signup-or-separator">
                <h1>
                  <span className="h6 signup-or-separator--text">Login With...</span>
                </h1>
                <hr />
              </div>
              {/* <div className='signup-or-separator'>
                <span className='h6 signup-or-separator--text'>
                  or
                </span>
                <hr />
              </div> */}
              <div className="alert alert-danger text-start d-none">
                <i className="bi bi-x-lg"></i>
                Those credentials do not match our records.
              </div>
              <form
                onSubmit={event => {
                  handleLogin(event, username, password)
                }}
                className="form-horizontal"
                name="f"
                method="post"
                action="/login_check"
                id="loginForm"
              >
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-bs-toggle="tooltip"
                    data-placement="bottom"
                    title=""
                    data-bs-original-title="Email"
                  >
                    @
                  </span>
                  <input
                    id="username"
                    className="form-control"
                    type="email"
                    tabIndex="1"
                    name="_username"
                    defaultValue=""
                    placeholder="Email"
                    onChange={event => {
                      setUsername(event.target.value)
                    }}
                  />
                </div>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    data-bs-toggle="tooltip"
                    data-placement="bottom"
                    title=""
                    data-bs-original-title="Password"
                    aria-label="Password"
                  >
                    <i className="bi bi-lock-fill"></i>
                  </span>
                  <input
                    id="password"
                    className="form-control"
                    type="password"
                    tabIndex="2"
                    name="_password"
                    placeholder="Password"
                    required
                    onChange={event => {
                      setPassword(event.target.value)
                    }}
                  />
                </div>
                {errorMessage && <div className="error">{errorMessage}</div>}
                <div className="mb-3 text-start remember-me">
                  <label id="i">
                    <input type="checkbox" name="_remember_me" defaultChecked="checked" defaultValue="on" />{" "}
                    <span className="lbl">Remember me</span>
                  </label>
                  <a className="forgotLink float-end" href="/resetting/request" data-modal="forgotPage">
                    Login Troubles?
                  </a>
                </div>
                <div className="mb-3">
                  <button type="submit" id="loginModalButton" className="btn btn-primary w-100">
                    <i className="bi bi-arrow-right-circle"></i>
                    Login
                  </button>
                </div>
              </form>
              <hr />
              <div className="text-center">
                <p className="termsCondition">
                  By signing up or logging in, you are agreeing to our fascinating &nbsp;
                  <a href="https://swiftcloud.ai/terms" target="_blank">
                    Terms of Use
                  </a>{" "}
                  &amp;
                  <a href="https://swiftcloud.ai/privacy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <LoginFooter />
    </>
  )
}

export default AdminLogin
